<template>
  <ai-field v-bind="props">
    <input
      v-if="!lazy"
      :id="name"
      ref="input"
      v-model="value"
      class="AiFieldInput-input"
      :class="{
        'AiFieldInput-input--inverse': inverse,
      }"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :autocomplete="autoComplete"
      :aria-expanded="expanded"
      :aria-controls="ariaControls"
      :aria-autocomplete="ariaAutoComplete"
      :aria-activedescendant="activedescendant"
      :aria-invalid="Boolean(errors)"
      :aria-describedby="`description-` + slugify(label ? label : '')"
      :role="ariaRole"
      @blur="$emit('blur')"
      @focus="$emit('focus')" />

    <input
      v-else-if="lazy"
      :id="name"
      ref="input"
      v-model.lazy="value"
      class="AiFieldInput-input"
      :class="{
        'AiFieldInput-input--inverse': inverse,
      }"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :autocomplete="autoComplete"
      :aria-expanded="expanded"
      :aria-controls="ariaControls"
      :aria-autocomplete="ariaAutoComplete"
      :aria-activedescendant="activedescendant"
      :aria-invalid="Boolean(errors)"
      :aria-describedby="`description-` + slugify(label ? label : '')"
      :role="ariaRole"
      @blur="$emit('blur')"
      @focus="$emit('focus')" />

    <!-- Slots forwarding -->
    <template v-if="$slots.helper" #helper>
      <slot name="helper" />
    </template>

    <template v-if="$slots.left" #left>
      <slot name="left" />
    </template>

    <template v-if="$slots.right" #right>
      <slot name="right" />
    </template>
  </ai-field>
</template>

<script setup lang="ts">
import AiField from './AiField.vue';

import { slugify } from '~~/helpers';

export type Props = {
  type?: 'text' | 'tel' | 'email' | 'number' | 'password';
  modelValue?: string | number;
  readonly?: boolean;
  expanded?: boolean;
  lazy?: boolean;
  // Field props
  inverse?: boolean;
  placeholder?: string;
  label?: string;
  name?: string;
  errors?: string[];
  successes?: string[];
  disabled?: boolean;
  autoComplete?: 'off' | undefined;
  ariaControls?: string;
  ariaAutoComplete?: 'none' | 'inline' | 'list' | 'both' | undefined;
  ariaRole?: string;
  activedescendant?: string;
  iconLeftClickable?: boolean;
  iconRightClickable?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: Props['modelValue']): void;
  (event: 'focus'): void;
  (event: 'blur'): void;
};

const input = ref<HTMLInputElement>();

defineExpose({ input });

const props = withDefaults(defineProps<Props>(), {
  activedescendant: undefined,
  autoComplete: undefined,
  ariaControls: undefined,
  ariaRole: undefined,
  ariaAutoComplete: undefined,
  errors: undefined,
  helper: undefined,
  label: undefined,
  modelValue: undefined,
  name: undefined,
  placeholder: undefined,
  successes: undefined,
  type: 'text',
});

const emits = defineEmits<Emits>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue: Props['modelValue']) {
    emits('update:modelValue', newValue);
  },
});
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/fonts';
@use '@/assets/styles/utilities/functions' as func;
@use '@/assets/styles/utilities/colors';

.AiFieldInput-input {
  all: unset;
  box-sizing: border-box;
  background-color: transparent;
  display: inline-block;
  width: 100%;
  border: none;
  min-height: func.calcRem(28);

  @include fonts.paragraph-04-bold;
  color: colors.$stratos-900;

  &--inverse {
    color: colors.$white;
  }
}

.AiFieldInput-input:disabled {
  color: colors.$neutral-700;
}

.AiFieldInput-input::placeholder {
  @include fonts.paragraph-04-regular;
  color: colors.$stratos-700;
}

.AiFieldInput-input--inverse::placeholder {
  color: colors.$neutral-300;
}
</style>
